export const fontSizeOptions = [
  {
    title: "Small (more content visible)",
    subtitle: "Recommended viewing distance of 3ft or less",
    size: 1,
  },
  {
    title: "Medium (default)",
    subtitle: "Recommended viewing distance of 6ft or less",
    size: 2,
  },
  {
    title: "Large (less content visible)",
    subtitle: "Recommended viewing distance of 12ft or less",
    size: 3,
  },
];
