import React, { useState } from "react";
import styled from "styled-components";
import {
  Modal,
  H1,
  H3,
  H4,
  P,
  Label,
  Select,
  Icon,
  ButtonPrimary,
  ButtonSecondary,
  GridRow,
  GridColumn,
  Footnote,
} from "notes";

export const AdjustEncoreModal = ({ onClose, ...props }) => {
  const conditionOptions = [
    { id: 1, text: "Add time" },
    { id: 2, text: "Reduce time" },
  ];
  let durationOptions = [
    { id: "30s", text: "30 Seconds" },
    { id: "1m", text: "1 Minute" },
    { id: "2m", text: "2 Minutes" },
  ];
  let length = 13;
  for (let index = 1; index < length; index++) {
    durationOptions.push({ id: index, text: `${index * 5} Minutes` });
  }

  const [condition, setCondition] = useState(conditionOptions[0]);
  const [duration, setDuration] = useState(durationOptions[0]);

  const handleClose = () => {
    onClose(false);
  };

  return (
    <ModalStyle setOpen={handleClose} size="medium" {...props}>
      <H3>Adjust Encore Timer</H3>
      <P>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </P>

      <LabelStyle>Adjust duration</LabelStyle>
      <GridRow style={{ justifyContent: "space-between" }}>
        <GridColumn columns={2}>
          <Select
            placeholder="Select an option..."
            selected={condition}
            onChange={setCondition}
            options={conditionOptions}
          />
        </GridColumn>
        <GridColumn columns={4}>
          <Select
            selected={duration}
            onChange={setDuration}
            placeholder="Select an option..."
            options={durationOptions}
          />
        </GridColumn>
      </GridRow>

      <TimeWrapper yCenter>
        <TimeContainer>
          <H4>Remaining time</H4>
          <H1>20:00</H1>
          <Footnote>7:00 PM PST End Time</Footnote>
        </TimeContainer>
        <Icon name="Right" />
        <TimeContainer>
          <H4>Adjusted time</H4>
          <H1>20:47</H1>
          <Footnote>7:01 PM PST End Time</Footnote>
        </TimeContainer>
      </TimeWrapper>

      <Actions xEnd yEnd>
        <ButtonSecondary onClick={handleClose}>Cancel</ButtonSecondary>
        <Primary>Confirm</Primary>
      </Actions>
    </ModalStyle>
  );
};

const TimeWrapper = styled(GridRow)`
  background-color: #fafafa;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
  height: 142px;

  svg {
    width: 42px;
    height: 42px;
    path {
      fill: ${(props) => props.theme.palette.black};
    }
  }
`;

const TimeContainer = styled(GridColumn)`
  width: 184px;

  ${H1}, ${H4}, ${Footnote} {
    text-align: center;
  }

  ${H4} {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  ${Footnote} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    font-style: normal;
    margin-top: 4px;
  }
`;

const ModalStyle = styled(Modal)`
  & > svg {
    display: none;
  }
  ${H3} + ${P} {
    margin-top: 8px;
  }
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
`;

const LabelStyle = styled(Label).attrs((props) => ({
  light: props.light || false,
}))`
  color: ${(props) =>
    props.light ? props.theme.palette.gray.lighter : props.theme.palette.black};
`;

const Actions = styled(GridRow)`
  width: 100%;
  margin-top: 40px;
  flex-grow: 1;
`;

const Primary = styled(ButtonPrimary)`
  margin-left: 8px;
`;
