import React, { createContext, useState } from "react";
import {
  DelayModal,
  EndModal,
  RestartModal,
  AdjustEncoreModal,
} from "../Components";
export const ModalContext = createContext();
export const ModalConsumer = ModalContext.Consumer;

export const ModalProvider = ({ children, ...props }) => {
  const [modal, setModal] = useState({
    delay: { open: false },
    end: { open: false },
    restart: { open: false },
    adjustEncore: { open: false },
  });

  const displayModal = (key) => {
    setModal({ ...modal, [key]: { open: true } });
  };

  const closeModal = (key) => {
    setModal({ ...modal, [key]: { open: false } });
  };

  return (
    <ModalContext.Provider
      value={{
        displayModal,
      }}
      {...props}
    >
      <DelayModal open={modal.delay.open} onClose={() => closeModal("delay")} />
      <EndModal open={modal.end.open} onClose={() => closeModal("end")} />
      <RestartModal
        open={modal.restart.open}
        onClose={() => closeModal("restart")}
      />
      <AdjustEncoreModal
        open={modal.adjustEncore.open}
        onClose={() => closeModal("adjustEncore")}
      />
      {children}
    </ModalContext.Provider>
  );
};
