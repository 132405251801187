import React, { useState } from "react";
import styled from "styled-components";
import { GridRow, GridColumn, H4 } from "notes";

export const Tabs = ({ children, tabStyle, ...props }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const selectTab = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  return (
    <TabsWrapper {...props}>
      <TabList style={tabStyle} role="tablist">
        {children.map(({ props: { label } }, index) => (
          <ButtonContainer yCenter>
            <TabButton
              xCenter
              yCenter
              role="tab"
              selected={selectedTab === index}
              aria-selected={selectedTab === index ? "true" : "false"}
              onClick={() => selectTab(index)}
            >
              <H4>{label}</H4>
            </TabButton>
            <TabBar active={selectedTab === index} />
          </ButtonContainer>
        ))}
      </TabList>

      <Content>
        {children.map((comp, index) =>
          selectedTab === index ? comp : undefined
        )}
      </Content>
    </TabsWrapper>
  );
};

const TabsWrapper = styled(GridColumn)``;

const TabBar = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${(props) => props.theme.palette.blue.bright};
  box-shadow: 0 2px 8px 0 ${(props) => props.theme.palette.blue.bright};
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  transition: all 0.2s ease;
  position: absolute;
  bottom: 0;
`;

const ButtonContainer = styled(GridColumn)`
  position: relative;
  height: 100%;
  & + & {
    margin-left: 40px;
  }
`;

const TabButton = styled(GridRow)`
  border-radius: 4px;
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  color: ${(props) =>
    props.selected ? props.theme.palette.blue.bright : "#ffffff"};
  height: 40px;
  outline: none;
  padding: 0 8px;
  width: 100%;
  transition: all 0.2s ease;
  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.selected ? "transparent" : props.theme.palette.blue.darker};
  }

  ${H4} {
    align-items: center;
    display: flex;
    font-weight: ${(props) => (props.selected ? "600" : "400")};
  }
  svg {
    margin-right: 9px;
    path {
      fill: ${(props) =>
        props.selected ? props.theme.palette.blue.bright : "#ffffff"};
    }
  }
`;

const TabList = styled(GridRow)`
  width: auto;
  height: 64px;
  padding: 0 24px;
`;

const Content = styled.div``;
