import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Duration } from "luxon";
import {
  SizeCard,
  ScaleElements,
  ModalContext,
  EventContext,
  TimeContext,
} from "Components";
import {
  ButtonPrimary,
  ButtonSecondary,
  GridColumn,
  Toggle,
  P,
  H2,
  H3,
} from "notes";

export const DefaultView = ({ onPreview, preview, ...props }) => {
  const { displayModal } = useContext(ModalContext);
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);

  const timeToStart = Duration.fromMillis(event.start - time).shiftTo(
    "hours",
    "minutes",
    "seconds",
    "milliseconds"
  );

  const handlePreview = (value) => {
    onPreview(value);
  };

  return (
    <Content {...props}>
      <TimeContainer column>
        <H2>
          {`${timeToStart.hours}:${timeToStart.minutes}:${timeToStart.seconds}`}
        </H2>
        <H3>Time to Show Start</H3>
      </TimeContainer>
      <SizeCard>
        <Toggle onChange={handlePreview} checked={preview} />
        <MarginLeft>Preview live experience</MarginLeft>
      </SizeCard>
      <PrimaryButton>Start Show</PrimaryButton>
      <DelayButton onClick={() => displayModal("delay")}>
        Delay Show Start
      </DelayButton>
    </Content>
  );
};

const TimeContainer = styled(ScaleElements)`
  width: 100%;
  margin-top: 64px;
  margin-bottom: 56px;

  ${H2},${H3} {
    color: #fff;
    text-align: center;
  }
`;

const Content = styled(GridColumn)`
  width: 100%;
  margin: 0 auto;
  max-width: 392px;
`;

const PrimaryButton = styled(ButtonPrimary)`
  margin-top: 24px;
  margin-bottom: 8px;
`;

const DelayButton = styled(ButtonSecondary)`
  border-color: #fff;
  color: #fff;
`;

const MarginLeft = styled(P)`
  margin-left: 12px;
`;
