import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  UserContext,
  Sidebar,
  firebase,
  EventContext,
  TimeContext,
} from "Components";
import { GridRow, Toggle, P } from "notes";
import { DefaultView, Live, Preview } from "./Components";
import backgroundImage from "Images/Background.png";
import { useDocument } from "react-firebase-hooks/firestore";

export const Root = () => {
  const { user } = useContext(UserContext);
  const [preview, setPreview] = useState(false);
  const {
    event: { start },
  } = useContext(EventContext);
  const { time } = useContext(TimeContext);

  return (
    <Wrapper yCenter>
      <Sidebar />
      <Container yStart xCenter>
        {start < time ? (
          <Live />
        ) : (
          <>
            {!preview && (
              <DefaultView onPreview={setPreview} preview={preview} />
            )}
            {preview && <Preview />}
            {preview && (
              <Card active={preview}>
                <Toggle checked={preview} onChange={setPreview} />
                <P style={{ marginLeft: "12px" }}>Preview live experience</P>
              </Card>
            )}
          </>
        )}
      </Container>
      <Background src={backgroundImage} />
    </Wrapper>
  );
};

const Card = styled(GridRow)`
  background-color: #ffffff;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.48), 0 0 16px 0 rgba(0, 0, 0, 0.64);
  border-radius: 8px;
  padding: 24px 40px;
  position: fixed;
  bottom: 40px;
  width: 392px;
`;

const Wrapper = styled(GridRow)`
  background-color: #1b2036;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const Container = styled(GridRow)`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: scroll;
  z-index: 1;
`;

const Background = styled.img`
  position: absolute;
  min-width: 1440px;
  width: 100%;
  z-index: 0;
`;
