import React from "react";
import styled from "styled-components";
import { GridColumn, GridRow, P, Icon } from "notes";

const tempUsers = [
  {
    rank: 1,
    displayName: "CHERRYWAVES",
    icon: (
      <img src="https://stage.set.live/assets/support-level-icons%2FRIbbon%2C%20Filled%20-%20Blue.png?alt=media" />
    ),
    amount: 25000,
  },
  { rank: 2, displayName: "miktimrob", icon: "", amount: 17500 },
  { rank: 3, displayName: "LecraeFan365", icon: "", amount: 15000 },
  { rank: 4, displayName: "Brian123456789", icon: "", amount: 10000 },
];

export const Leaderboard = ({ users = tempUsers }) => {
  return (
    <Container stretch>
      <Title>Top 10 Supporters</Title>
      {users.map((user) => (
        <SupportRow yCenter>
          <GridRow stretch yCenter>
            <Name>
              {user.rank < 10 ? `0${user.rank}. ` : `${user.rank}. `}
              {user.displayName}
            </Name>
            <IconContainer>{user.icon}</IconContainer>
          </GridRow>
          <Amount>${!!user.amount && (user.amount / 100).toFixed()}</Amount>
          <NextIcon tertiary name="Next" />
        </SupportRow>
      ))}
    </Container>
  );
};

const Container = styled(GridColumn)`
  padding: 24px;
`;

const Title = styled(P)`
  color: #fff;
  ${(props) => props.theme.fonts.header};
  font-weight: 300;
  margin-bottom: 8px;
`;

const SupportRow = styled(GridRow)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.primary};
  padding: 9px 12px;

  &:hover {
    background-color: #191b30;
  }
`;

const Name = styled(GridColumn)`
  color: #ffffff;
  margin-right: 14px;
`;

const Amount = styled(GridColumn)`
  color: ${(props) => props.theme.palette.gray.lighter};
  font-size: 15px;
`;

const IconContainer = styled(GridColumn)`
  align-items: flex-start;
  width: 16px;
  height: 16px;
  img {
    height: 100%;
  }
`;

const NextIcon = styled(Icon)`
  margin-left: 4px;
  path {
    fill: ${(props) => props.theme.palette.gray.lighter};
  }
`;
