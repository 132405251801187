import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, H4, P } from "notes";
import { ModalContext, ProgressBar } from "Components";

const tempSongs = [
  { title: "Deep End", percent: 80 },
  { title: "Coming In Hot", percent: 70 },
  { title: "Set Me Free", percent: 60 },
  { title: "Get Back Right", percent: 50 },
];

export const Encore = ({ remainingTime = "00:20:17", songs = tempSongs }) => {
  const { displayModal } = useContext(ModalContext);

  return (
    <Container stretch>
      <RemainingTime>
        <Time>{remainingTime}</Time>
        <P>To Voting Close</P>
        <AdjustEncore onClick={() => displayModal("adjustEncore")}>
          Adjust
        </AdjustEncore>
      </RemainingTime>
      {songs.map((song) => (
        <ProgressBar {...song} />
      ))}
    </Container>
  );
};

const Container = styled(GridColumn)`
  padding: 24px;
`;

const RemainingTime = styled(GridRow)`
  background-color: #191b30;
  color: #ffffff;
  margin-bottom: 16px;
  padding: 9px 12px;
`;

const AdjustEncore = styled(H4)`
  cursor: pointer;
  font-weight: 700;
`;

const Time = styled(H4)`
  font-weight: 700;
  margin-right: 8px;
  & + ${P} {
    font-size: 15px;
    flex-grow: 1;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
