import React, { useState } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, Toggle, Icon } from "notes";
import { formatCurrency } from "Components/ChatMessage/RenderMessage";

export const AttendeeTable = React.memo(({ items, toggleMute, ...props }) => {
  const [sort, setSort] = useState({ key: "flaggedCount", ascending: true });
  const handleSort = (key) => {
    if (sort.key === key) {
      setSort({ ...sort, ascending: !sort.ascending });
    } else {
      setSort({ key, ascending: true });
    }
  };
  let sortedItems = [];
  if (items.length) {
    switch (typeof items[0][sort.key]) {
      case "number":
        sortedItems = items.sort((a, b) => a[sort.key] - b[sort.key]);
        break;
      case "string":
        sortedItems = items.sort((a, b) =>
          a[sort.key].localeCompare(b[sort.key])
        );
        break;
      default:
        sortedItems = items.sort((a, b) => a[sort.key] - b[sort.key]);
        break;
    }
    if (sort.ascending) {
      sortedItems = sortedItems.reverse();
    }
  }
  return (
    <Container stretch>
      <HeaderRow>
        <HeaderColumn stretch onClick={() => handleSort("name")}>
          Name
        </HeaderColumn>
        <HeaderColumn onClick={() => handleSort("username")}>
          Display Name
        </HeaderColumn>
        <HeaderColumn onClick={() => handleSort("spend")}>Spend</HeaderColumn>
        <HeaderColumn onClick={() => handleSort("flaggedCount")}>
          Flagged Chats
        </HeaderColumn>
        <HeaderColumn
          style={{ width: "110px" }}
          onClick={() => handleSort("muted")}
        >
          Chat
        </HeaderColumn>
      </HeaderRow>
      {!!items &&
        items.map(({ name, uid, username, spend, flaggedCount, muted }) => (
          <Row>
            <ColumnPadding stretch>{name}</ColumnPadding>
            <ColumnPadding>{username}</ColumnPadding>
            <Column>{formatCurrency(spend)}</Column>
            <Column>{flaggedCount}</Column>
            <StaticWidth>
              <Toggle
                checked={!muted}
                onChange={() => toggleMute(uid, muted)}
              />
            </StaticWidth>
          </Row>
        ))}
    </Container>
  );
});

export const Container = styled(GridColumn)`
  margin-top: 16px;
`;

export const HeaderRow = styled(GridRow)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.primary};
  padding-bottom: 6px;
`;

export const Column = styled(GridColumn)`
  display: inline-block;
  color: #fff;
  width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ColumnPadding = styled(Column)`
  padding-right: 24px;
`;

export const HeaderColumn = styled(Column)`
  font-size: 14px;
  cursor: pointer;
`;

export const StaticWidth = styled(GridRow)`
  align-items: center;
  width: 110px;
  svg {
    margin-left: 18px;
    width: 24px;
    height: 24px;
    path {
      fill: #fff;
    }
  }
`;

export const Row = styled(GridRow)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.primary};
  padding: 9px 0;
`;
