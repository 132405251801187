import React from "react";
import styled from "styled-components";
import { Modal, H3, P, ButtonPrimary, ButtonSecondary, GridRow } from "notes";

export const EndModal = ({ onClose, ...props }) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <ModalStyle setOpen={handleClose} size="small" {...props}>
      <H3>End Show</H3>
      <P>Are you sure you want to end the show?</P>

      <Actions xEnd yEnd>
        <ButtonSecondary onClick={handleClose}>Cancel</ButtonSecondary>
        <Primary>Confirm</Primary>
      </Actions>
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  ${H3}, ${P} {
    text-align: center;
  }
  ${H3} {
    margin-bottom: 16px;
  }
`;

const Actions = styled(GridRow)`
  width: 100%;
  margin-top: 40px;
  flex-grow: 1;
`;

const Primary = styled(ButtonPrimary)`
  margin-left: 8px;
`;
