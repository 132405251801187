import React, { Component, useContext } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ActionCard, EventContext } from "Components";
import { firebase } from "Components";

const SortableItem = SortableElement(({ card }) => <ActionCard card={card} />);

const SortableList = SortableContainer(({ results }) => {
  return (
    <div style={{ marginBottom: "-8px" }}>
      {results.map((card, index) => (
        <SortableItem key={`item-${card.id}`} index={index} card={card} />
      ))}
    </div>
  );
});

export const SortableCards = ({ query, index }) => {
  const [results, load, err] = useCollection(query);
  const { event } = useContext(EventContext);
  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const aboveDoc = results.docs[newIndex + (oldIndex > newIndex ? -1 : 0)];
    const belowDoc = results.docs[newIndex + (oldIndex < newIndex ? 1 : 0)];
    const above = aboveDoc ? aboveDoc.data()[index] : 0;
    const below = belowDoc ? belowDoc.data()[index] : Number.MAX_VALUE;
    const newOrder = above / 2 + below / 2;
    const res = await firebase
      .firestore()
      .collection(`artist_views/${event.id}/cards`)
      .doc(results.docs[oldIndex].id)
      .update({ [index]: newOrder });
  };
  if (load) {
    return null;
  }

  return (
    <SortableList
      results={results.docs}
      onSortEnd={onSortEnd}
      lockToContainerEdges
      lockAxis={"y"}
    />
  );
};
