import React, { useContext } from "react";
import { Icon, P } from "notes";
import styled from "styled-components";
import { EventContext, firebase } from "Components";
import { useCollection } from "react-firebase-hooks/firestore";
export const PinSeparator = () => {
  const { event } = useContext(EventContext);
  const [results, load, err] = useCollection(
    firebase
      .firestore()
      .collection(`artist_views/${event.id}/cards`)
      .where("status", "==", "pinned")
  );
  if (!results?.docs) {
    return null;
  }
  return (
    <Container>
      <Icon tertiary name="Pin" style={{ padding: "16px" }} />
      <Line />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  height: 1px;
  width: calc(100% - 24px);
  background: #596266;
`;
