import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, Icon, H3 } from "notes";
import { EventContext, ModalContext, Dropdown } from "Components";
import { fontSizeOptions } from "Data";

export const Sidebar = () => {
  const { setContentSize, contentSize } = useContext(EventContext);
  const { displayModal } = useContext(ModalContext);
  return (
    <Container>
      <EventImage />
      <Dropdown
        active={contentSize}
        items={fontSizeOptions}
        onClick={(size) => setContentSize(size)}
      />

      <Divider />
      <Time xCenter>
        <Icon form name="Clock" />
        <Minutes>00</Minutes>
        <Seconds>:00</Seconds>
      </Time>
      <Divider />
      <GridColumn stretch yEnd>
        <EndShowButton xCenter yCenter onClick={() => displayModal("end")}>
          End Show
        </EndShowButton>
      </GridColumn>
    </Container>
  );
};

const EndShowButton = styled(GridColumn)`
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.red.primary
      : props.theme.palette.gray.lightest};
  color: ${(props) =>
    props.active ? "#ffffff" : props.theme.palette.gray.lighter};
  cursor: ${(props) => (props.active ? "pointer" : "default")};
  text-align: center;
  line-height: 18px;
  font-weight: 700;
  width: 56px;
  height: 56px;
  &:hover {
    background-color: ${(props) =>
      props.active ? "#FC4E42" : props.theme.palette.gray.lightest};
  }
`;

const EventImage = styled(GridColumn)`
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 56px;
  height: 56px;
`;

const Container = styled(GridColumn)`
  background-color: #20273f;
  width: 56px;
  height: 100%;
  position: relative;
  z-index: 2;
`;

const Time = styled(GridColumn)`
  width: 56px;
  padding: 20px 0;
  svg {
    margin-bottom: 8px;
    width: 16px;
    height: 16px;
    path {
      fill: #fff;
    }
  }
  ${H3} {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const Minutes = styled(H3)`
  color: #ffffff;
  font-weight: 700;
`;

const Seconds = styled(H3)`
  color: #7aa3cc;
  font-weight: 300;
`;

const Divider = styled.div`
  background-color: #435282;
  width: 100%;
  height: 1px;
`;
