import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridRow, H4, Logo, Icon, TertiaryMenu } from "notes";
import { firebase } from "Components";

import { runDemo } from 'Components/Demo';

const StyledIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const menuItems = [
  // {
  //   key: "/",
  //   icon: <StyledIcon tertiary name="User" />,
  //   label: "Artist View",
  // },
  {
    key: 'demo',
    label: 'Run Demo'
  },
  {
    key: "signout",
    icon: <StyledIcon tertiary name="Exit" />,
    label: "Sign Out",
  },
];

export const Header = () => {
  const history = useHistory();

  const [demoRunning, setDemoRunning] = useState(false);

  let username = "Set Live";
  
  const user = firebase.auth().currentUser;
  if (user) {
    username = user.displayName;
  }

  const handleLogout = async () => {
    const options = {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include'
    };

    Promise.allSettled([
      firebase.auth().signOut(),
      fetch('/auth/endSession', options)
    ]).then(() => {
      history.push("/login");
    });
  };

  const handleRunDemo = async() => {
    const match = window.location.pathname.match(/\/(?<eventId>[^\/]+)\//);

    if (!demoRunning) {
      console.log("Running Demo!");
      
      runDemo(match.groups.eventId);

      setDemoRunning(true);
    } else {
      console.log("Demo already running");
    }
  }

  const handleMenu = (key) => {
    if (key === "signout") {
      handleLogout();
    } else if (key === 'demo') {
      handleRunDemo();
    }else {
      history.push(`/${key}`);
    }
  };

  return (
    <Container yCenter>
      <Logo name="SetLiveReversed" />

      <TertiaryMenu
        offset="0, 10"
        items={menuItems}
        onSelect={(key) => handleMenu(key)}
        trigger={(props) => (
          <UserButton yCenter {...props}>
            <UserIcon image={false} xCenter yCenter>
              {username.charAt(0)}
            </UserIcon>
            <UserName>{username}</UserName>
            <Caret indicator name="Dropdown" />
          </UserButton>
        )}
      />
    </Container>
  );
};

const UserButton = styled(GridRow).attrs({ as: "div" })`
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 12px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0;
  }
`;

const UserIcon = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.orange.primary};
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  border-radius: 16px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin-right: 16px;
  overflow: hidden;
  text-transform: uppercase;
  text-indent: ${(props) => (!!props.image ? "-50px" : 0)};
  width: 32px;
  height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 7px;
  }
`;

const Caret = styled(Icon)`
  path {
    fill: #fff;
  }
`;

const UserName = styled(H4)`
  color: #fff;
  font-weight: 600;
  margin-right: 12px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const Container = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.blue.dark};
  justify-content: space-between;
  height: 56px;
  padding: 0 24px;
  ${UserName} {
    color: #ffffff;
  }
  ${Caret} {
    path {
      fill: #ffffff;
    }
  }
`;
