import React, { useContext } from "react";
import styled from "styled-components";
import { ModalContext, EventContext, firebase } from "Components";
import { GridColumn, Grid, Icon, H2, H3, Link, GridRow, H4 } from "notes";
import { RenderMessage } from "../ChatMessage/RenderMessage";
import { getCardIcon } from "../ChatMessage";

export const ActionCard = ({ card }) => {
  const { displayModal } = useContext(ModalContext);
  const { event } = useContext(EventContext);
  const { type = "note", content, status } = card?.data();
  const artistViewRef = firebase.firestore().doc(`artist_views/${event.id}`);
  const ref = firebase
    .firestore()
    .doc(`artist_views/${event.id}/cards/${card.id}`);

  const handlePin = async () => {
    const res = await ref.update({ status: "pinned" });
  };
  const handleQueue = async () => {
    const res = await ref.update({ status: "pinned" });
  };
  const handleBackToQueue = async () => {
    const res = await ref.update({
      status: "pinned",
      queueOrder: Number.MAX_VALUE - Math.random(),
    });
  };
  const handleDone = async () => {
    if (card.data().type === "encore") {
      artistViewRef.update({ encoreDocId: null });
    }
    if (card.data().type === "leaderboard") {
      artistViewRef.update({ leaderboardDocId: null });
    }
    const res = await ref.update({ status: "archive" });
  };
  const handleDelete = async () => {
    if (card.data().type === "encore") {
      artistViewRef.update({ encoreDocId: null });
    }
    if (card.data().type === "leaderboard") {
      artistViewRef.update({ leaderboardDocId: null });
    }
    const res = await ref.update({ status: "deleted" });
  };

  return (
    <Container row stretch>
      <HoverState xEnd yCenter>
        <Action
          style={{
            position: "absolute",
            left: "24px",
            fontWeight: "400",
            fontStyle: "italic",
          }}
        >
          <Icon tertiary name="arrows" />
          Drag and drop to order
        </Action>
        {type === "encore" && (
          <Action onClick={() => displayModal("adjustEncore")}>
            <Icon tertiary name="Clock" />
            Update Encore Timer
          </Action>
        )}
        {status === "live" && (
          <Action onClick={handleBackToQueue}>
            <Icon tertiary name="MoveUp" />
            Move Back to Queue
          </Action>
        )}
        {status === "live" && (
          <Action onClick={handleDone}>
            <Icon tertiary name="Archive" />
            Mark As Done
          </Action>
        )}
        {!["live", "archived", "deleted"].includes(status) && (
          <Action onClick={handleDelete}>
            <Icon tertiary name="Archive" />
            Delete
          </Action>
        )}
        {status === "queue" && (
          <Action onClick={handlePin}>
            <Icon tertiary name="Pin" />
            Pin
          </Action>
        )}
        {["archive", "deleted"].includes(status) && (
          <Action onClick={handlePin}>
            <Icon tertiary name="Pin" />
            Move back to Pinned
          </Action>
        )}
      </HoverState>

      <BlurContainer stretch>
        <LeftContainer noShrink type={type} xCenter yStart>
          {getCardIcon(type)}
        </LeftContainer>
        <RightContainer type={type} stretch>
          <Title type={type}>
            {type !== "question" ? type : content.username}
          </Title>
          {type !== "encore" && type !== "leaderboard" ? (
            <RenderMessage managed type={type} content={content} />
          ) : type === "encore" ? (
            <LightText>The top 3 encore requests will appear here</LightText>
          ) : (
            <LightText>The top 3 fans will appear here</LightText>
          )}
        </RightContainer>
      </BlurContainer>
    </Container>
  );
};

const LightText = styled(H4)`
  color: #ffffff;
  font-weight: 300;
  font-style: italic;
`;

const Action = styled(Link)`
  color: #fff;
  &:hover {
    color: #fff;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  & + & {
    margin-left: 24px;
  }

  svg {
    margin-right: 7px;
    width: 16px;
    height: 16px;
    path {
      fill: #ffffff;
    }
  }
`;

const HoverState = styled(GridRow)`
  background-color: rgba(67, 82, 130, 0.42);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  transition: all ease 0.3s;
  padding: 0 16px;
`;

const BlurContainer = styled(GridRow)`
  filter: blur(0);
  transition: all ease 0.3s;
`;

const Container = styled(GridRow)`
  border-radius: 4px;
  margin-bottom: 8px;
  overflow: hidden;
  position: relative;

  ${H2}, ${H3} {
    font-size: 17px;
    line-height: 22px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    ${HoverState} {
      opacity: 1;
      visibility: visible;
    }
    ${BlurContainer} {
      filter: blur(5px);
    }
  }
`;

const LeftContainer = styled(GridColumn)`
  background-color: ${(props) =>
    props.type === "note"
      ? props.theme.palette.blue.primary
      : props.type === "supporters"
      ? props.theme.palette.green.primary
      : props.type === "encore"
      ? props.theme.palette.purple.primary
      : props.type === "question"
      ? props.theme.palette.teal.primary
      : props.theme.palette.yellow.primary};
  border-radius: 4px 0 0 4px;
  padding: 12px;

  svg {
    width: 22px;
    height: 22px;
    path {
      fill: #fff;
    }
  }
`;

const Title = styled(H2)`
  ${(props) => props.theme.fonts.default};
  font-weight: 700;
  margin-right: 12px;
  text-transform: ${(props) =>
    props.type !== "question" ? "capitalize" : "none"};
  color: ${(props) =>
    props.type === "note"
      ? props.theme.palette.blue.primary
      : props.type === "supporters"
      ? props.theme.palette.green.primary
      : props.type === "encore"
      ? props.theme.palette.purple.primary
      : props.type === "question"
      ? props.theme.palette.teal.primary
      : props.theme.palette.yellow.primary};
`;

const RightContainer = styled(Grid)`
  background-color: ${(props) => props.theme.palette.blue.dark};
  padding: 12px;
  ${H2} {
    ${(props) => props.theme.fonts.default};
  }
`;
