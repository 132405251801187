import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, P, Footnote, Icon, Link } from "notes";
import { ReactComponent as TextSizeIcon } from "Images/TextSizeIcon.svg";

export const Dropdown = ({ items, active, onClick, textLink, ...props }) => {
  const [open, setOpen] = useState(false);
  const [fieldRef, setFieldRef] = useState(false);

  const handleClickOutside = (evt) => {
    if (!fieldRef.contains(evt.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const handleClick = (size) => {
    onClick(size);
  };

  return (
    <Wrapper ref={(component) => setFieldRef(component)}>
      {textLink ? (
        <Link
          onClick={() => setOpen(!open)}
          style={{ color: "#fff", margin: "0 0 8px 8px" }}
        >
          display font: {items.find(({ size }) => size === active)?.title}
        </Link>
      ) : (
        <Button active={open} xCenter yCenter onClick={() => setOpen(!open)}>
          <TextSizeIcon />
          <Caret indicator name="Dropdown" />
        </Button>
      )}
      {open && (
        <Container>
          {items.map(({ title, subtitle, size }) => (
            <Item active={active === size} onClick={() => handleClick(size)}>
              <P>{title}</P>
              <Footnote>{subtitle}</Footnote>
            </Item>
          ))}
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(GridRow).attrs({ as: "div" })`
  display: flex;
  position: relative;
  z-index: 10;
`;

const Container = styled(GridColumn)`
  background-color: #ffffff;
  position: absolute;
  left: 64px;
  width: 288px;
`;

const Item = styled(GridColumn)`
  ${(props) =>
    props.active && `background-color: ${props.theme.palette.gray.lightest}`};
  cursor: ${(props) => (props.active ? "default" : "pointer")};
  padding: 16px 12px;
`;

const Caret = styled(Icon)`
  && {
    width: 9px;
    height: 9px;
    margin-left: 3px;
  }
`;

const Button = styled(GridRow)`
  background-color: ${(props) => (props.active ? "#27b0ff" : "#20273f")};
  cursor: pointer;
  width: 56px;
  height: 56px;
  &:hover {
    background-color: #27b0ff;
  }
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: #fff;
    }
  }
`;
