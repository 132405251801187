import React, { useContext, useState } from "react";
import { EventContext, SortableCards, firebase } from "Components";
import styled from "styled-components";
import { P, Select } from "notes";

const views = [
  { text: "Archive", id: "archive" },
  { text: "Deleted", id: "deleted" },
];

export const ArchiveCards = () => {
  const { event } = useContext(EventContext);
  const [view, setView] = useState(views[0]);
  const ref = firebase.firestore().collection(`artist_views/${event.id}/cards`);

  return (
    <Container>
      <div style={{ width: "150px", marginBottom: "12px" }}>
        <Select options={views} selected={view} onChange={setView} />
      </div>
      <SortableCards
        query={ref.where("status", "==", view.id).orderBy("queueOrder")}
        index={"queueOrder"}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 24px;
`;

const Header = styled(P)`
  color: #fff;
`;
