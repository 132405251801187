import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, Select, Input, ButtonPrimary, Icon } from "notes";
import { AttendeeTable, ChatTable } from "./Components";
import { ChatManager, EventContext, firebase } from "Components";

export const Moderation = ({ ...props }) => {
  const { event } = useContext(EventContext);
  const [option, setOption] = useState({
    id: 1,
    text: "Attendees",
    type: "user",
  });
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [ChatService, setChatService] = useState();
  const executeSearch = useRef();
  useEffect(() => {
    const mgr = new ChatManager(event.id, firebase);
    setChatService(mgr);
  }, [event.id]);

  executeSearch.current = async () => {
    if (ChatService) {
      const res = await ChatService.search({
        query: search,
        type: option.type,
      });
      setResults(res);
    }
  };

  const toggleMute = useMemo(
    () => async (id, currentStatus) => {
      const res = await firebase
        .database()
        .ref(`${event.id}/users/${id}`)
        .update({ muted: !currentStatus }, (error) => {
          executeSearch.current();
        });
    },
    []
  );

  const muteChat = useMemo(
    () => async (id, currentStatus) => {
      const res = await firebase
        .database()
        .ref(`${event.id}/chatMessages/${id}`)
        .update({ muted: !currentStatus }, (error) => {
          executeSearch.current();
        });
    },
    []
  );

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      executeSearch.current();
    }
  };

  return (
    <Container {...props} stretch>
      <SearchContainer>
        <SearchSelect
          options={[
            { id: 1, text: "Attendees", type: "user" },
            { id: 2, text: "Chat", type: "message" },
            { id: 2, text: "Chats by User", type: "user_message" },
          ]}
          selected={option}
          onChange={(value) => {
            setSearch("");
            setOption(value);
            setResults([]);
          }}
        />
        <SearchInput
          value={search}
          onChange={setSearch}
          leftIcon={<Icon form name="Search" />}
          rightIcon={
            !!search ? (
              <Icon
                style={{ cursor: "pointer" }}
                onClick={() => setSearch("")}
                form
                name="RemoveCircle"
              />
            ) : undefined
          }
          style={{ borderRadius: "0 4px 4px 0" }}
          placeholder={
            option.id === 2
              ? "Search for an attendee by display name or chat keyword..."
              : "Search for an attendee by legal or display name..."
          }
          onKeyDown={handleKeyDown}
        />
        <SearchButton onClick={executeSearch.current}>Search</SearchButton>
      </SearchContainer>
      {option.id === 1 && (
        <AttendeeTable items={results} toggleMute={toggleMute} />
      )}
      {[2, 3].includes(option.id) && (
        <ChatTable items={results} muteChat={muteChat} />
      )}
    </Container>
  );
};

const Container = styled(GridColumn)`
  padding: 40px 24px;
`;

const SearchContainer = styled(GridRow)``;

const SearchSelect = styled(Select)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 184px;
`;

const SearchInput = styled(Input)`
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const SearchButton = styled(ButtonPrimary)`
  margin-left: 24px;
  min-width: 80px;
`;
