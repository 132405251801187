import React, { useContext } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Grid, H2, H3, Icon } from "notes";
import { EventContext } from "Components";
import { theme } from "theme";

export const ScaleElements = ({ children, ...props }) => {
  const { contentSize } = useContext(EventContext);
  const updatedTheme = {
    ...theme,
    headers: {
      ...theme.headers,
      h2: {
        ...theme.headers.h2,
        fontSize:
          contentSize === 1 ? "32px" : contentSize === 3 ? "55px" : "41px",
        lineHeight:
          contentSize === 1 ? "44px" : contentSize === 3 ? "70px" : "48px",
        fontWeight: "700",
        letterSpacing: "0",
      },
      h3: {
        ...theme.headers.h3,
        fontSize:
          contentSize === 1 ? "25px" : contentSize === 3 ? "40px" : "25px",
        lineHeight:
          contentSize === 1 ? "32px" : contentSize === 3 ? "44px" : "32px",
        fontWeight: "700",
        textTransform: "none",
      },
    },
  };
  return (
    <ThemeProvider theme={updatedTheme}>
      <Container size={contentSize} {...props}>
        {children}
      </Container>
    </ThemeProvider>
  );
};

const Container = styled(Grid)`
  ${H2}, ${H3} {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all ease 0.3s;
  }

  position: relative;

  svg {
    transition: all ease 0.3s;
    width: ${(props) =>
      props.size === 1 ? "36px" : props.size === 3 ? "60px" : "50px"};
    height: ${(props) =>
      props.size === 1 ? "36px" : props.size === 3 ? "60px" : "50px"};
  }
`;
