/**
 * Process a bunch of async tasks in chunks.
 *
 * Inspired by: https://github.com/sergelerator/async-batch
 */
const asyncBatch = async (tasks, handler, chunkSize) => {
  const workersCount = Math.max(
    Math.floor(Math.min(chunkSize, tasks.length)),
    1
  );
  const results = [];
  let i = 0;
  await Promise.allSettled(
    Array.from({ length: workersCount }).map(async (w, workerIndex) => {
      while (i < tasks.length) {
        const taskIndex = i;
        i++;

        let result = null;
        try {
          result = await handler(...tasks[taskIndex]);
        } catch (error) {
          console.error(`Error running asyncBatch task #${i}`, error);
          console.log(tasks[taskIndex]);
        }

        results[taskIndex] = result;
      }
    })
  );
  return results;
};

export class ChatManager {
  constructor(eventId, firebase) {
    // Event ID
    this.eventId = eventId;

    // Firebase SDK
    this.firebase = firebase;

    // Holds all messages, keyed by message ID
    this.messages = new Map();

    // Holds all message contents, keyed by message ID
    this.contents = new Map();

    //
    // Holds all users, keyed by user ID.  The user object is a combination of
    // data from a bunch of places including:
    //
    //    * username (from chatMessages in rtdb)
    //    * flagged messages count
    //    * full name (from profile collection in firestore)
    //    * total spend (from leaderboard/totals in rtdb)
    //    * tier (from events in firestore)
    //    * muted (from users in rtdb)
    //
    this.users = new Map();

    this._paths = {
      chatMessages: `${eventId}/chatMessages`,
      leaderboardTotals: `${eventId}/leaderboard/totals`,
      users: `${eventId}/users`,
      chatFlags: `${eventId}/chatFlags`,
    };

    this._handlers = [];

    // Bind the listeners
    this.listen();
  }

  /**
   *
   * Public interface to search for user.
   *
   */
  async search(params) {
    const { query, type } = params;

    if (type === "message") {
      return await this.searchByMessage(query);
    } else if (type === "user") {
      return await this.searchByUser(query);
    } else if (type === "user_message") {
      return await this.searchMessagesByUser(query);
    }

    throw new Error(`Invalid query type ${type}`);
  }

  /**
   * Binds the listener to chat messages.  As new messages come in, they are
   * filtered and then added to the system.
   *
   */
  async listen() {
    if (this._handlers.length < 1) {
      // Load initial data
      await this._load();
    }

    this._addHandler(this._paths.chatMessages, "child_changed", (snap) => {
      const data = snap.val() || {};

      // If the message isn't a 'user' message, we don't care about it
      if (data.type !== "user") {
        return;
      }

      this.addMessage([[snap.key, data]]);
    });

    this._addHandler(this._paths.leaderboardTotals, "child_changed", (snap) => {
      const uid = snap.key;
      const spend = snap.val();

      this._updateUser(uid, { spend });
    });

    this._addHandler(this._paths.users, "child_changed", (snap) => {
      this._updateUser(snap.key, snap.val());
    });

    this._addHandler(this._paths.chatFlags, "child_changed", (snap) => {
      this._handleFlaggedMessage(snap.key, snap.val());
    });
  }

  _handleFlaggedMessage(messageId, flags) {
    const message = this.messages.get(messageId);
    if (message) {
      const user = this.users.get(message.uid);
      if (user) {
        const flaggedMessages = user.flaggedMessages || {};
        flaggedMessages[messageId] = Object.keys(flags).length;
        this._updateUser(message.uid, { flaggedMessages });
      }
    }
  }

  async _loadMessages() {
    const messages = await this.firebase
      .database()
      .ref(this._paths.chatMessages)
      .once("value");

    const filtered = [];
    messages.forEach((snap) => {
      const data = snap.val() || {};

      if (data.type !== "user") {
        return;
      }
      filtered.push([snap.key, { ...data, id: snap.key }]);
    });

    return await this.addMessage(filtered);
  }

  async _loadUsers() {
    const users = await this.firebase
      .database()
      .ref(this._paths.users)
      .once("value");

    users.forEach((snap) => {
      this._updateUser(snap.key, snap.val());
    });
  }

  async _loadMessageFlags() {
    const flags = await this.firebase
      .database()
      .ref(this._paths.chatFlags)
      .once("value");

    flags.forEach((snap) => {
      this._handleFlaggedMessage(snap.key, snap.val());
    });
  }

  async _loadSpend() {
    const totals = await this.firebase
      .database()
      .ref(this._paths.leaderboardTotals)
      .once("value");

    totals.forEach((snap) => {
      this._updateUser(snap.key, { spend: snap.val() });
    });
  }

  async _load() {
    await Promise.allSettled([
      this._loadMessages(),
      this._loadUsers(),
      this._loadMessageFlags(),
      this._loadSpend(),
    ]);
  }

  _addHandler(path, event, cb) {
    const key = `${path}:${event}`;

    const ref = this.firebase.database().ref(path);

    this._handlers[key] = { ref, cb };

    ref.on(event, (snap) => {
      console.debug(`Recv from ${key}`, snap);

      try {
        cb(snap);
      } catch (error) {
        console.error(`Error calling handler for ${key}`, error);
      }
    });
  }

  _removeHandler(path, event) {
    const key = `${path}:${event}`;

    const { ref, cb } = this._handlers[key] || {};

    if (ref && cb) {
      try {
        ref.off(event, cb);
      } catch (error) {
        console.error("Error removing handler", error);
      }
    }
  }

  async addMessage(messages) {
    let count = 0;

    if (!(messages instanceof Array)) {
      messages = [messages];
    }

    const uids = new Map();

    for (const [messageId, message] of messages) {
      if (this._addMessage(messageId, message)) {
        const { uid } = message;
        if (!this.users.has(uid)) {
          // New user
          uids.set(uid, { username: message.user });
        }
        count++;
      }
    }

    await asyncBatch(
      Array.from(uids.entries()),
      this._updateUser.bind(this),
      10
    );

    return count;
  }

  /**
   *
   * @param query string - Text to search for
   */
  async searchByMessage(query) {
    const filtered = [];

    query = this._formatContent(query);

    for (const [messageId, value] of this.contents) {
      const isMatch =
        value.normalized.indexOf(query.normalized) > -1 ||
        value.reduced.indexOf(query.reduced) > -1;

      if (isMatch) {
        const message = this.messages.get(messageId);
        if (message) {
          filtered.push(message);
        }
      }
    }

    return filtered;
  }

  async searchByUser(query) {
    const filtered = [];

    query = query.toLowerCase();

    for (let [uid, user] of this.users) {
      const username = (user.username || "").toLowerCase();
      const fullname = (user.fullname || "").toLowerCase();

      const isMatch =
        username.indexOf(query) > -1 || fullname.indexOf(query) > -1;

      if (isMatch) {
        filtered.push({ ...user, uid });
      }
    }

    return filtered;
  }

  async searchMessagesByUser(query) {
    const users = await this.searchByUser(query);

    const usernames = users.map((user) => user.username);

    const filtered = [];
    for (const [messageId, value] of this.contents) {
      const message = this.messages.get(messageId);
      if (usernames.indexOf(message.user) > -1) {
        filtered.push(message);
      }
    }

    return filtered;
  }

  _formatContent(content) {
    if (!content) {
      content = "";
    }

    const normalized = content.normalize().toLowerCase();

    return {
      normalized: normalized,
      reduced: normalized.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ {2,}/, " "),
    };
  }

  _addMessage(messageId, message) {
    try {
      this.contents.set(messageId, this._formatContent(message.content));
      this.messages.set(messageId, { ...message, id: messageId });
      this._updateUser(message.uid, { username: message.user });
      return true;
    } catch (error) {
      console.error(`Error loading message ${messageId}`, error);
    }

    return false;
  }

  /**
   *
   * @param string uid    User ID to update
   * @param Object data   Data to merge into user
   */
  async _updateUser(uid, data = {}) {
    let user = {
      ...{
        muted: false,
        spend: 0,
      },
      ...(this.users.get(uid) || {}),
      ...data,
    };

    if (!user.flaggedMessages) {
      user.flaggedMessages = {};
    }

    user.flaggedCount = Object.values(user.flaggedMessages).reduce(
      (a, b) => a + b,
      0
    );

    user.name = user.username;
    if (user.fullname) {
      user.name = user.fullname;
    }

    this.users.set(uid, user);
  }
}
