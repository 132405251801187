import { MAXTheme } from "notes";

export const theme = {
  ...MAXTheme,
  colors: {
    ...MAXTheme.colors,
    action: "#27B0FF",
    buttonPrimaryBgHover: "#4780A1",
    noteButtonHover: "#5571CC",
  },
  palette: {
    ...MAXTheme.palette,
    blue: {
      primary: "#6B8EFF",
      lighter: "#BCDBE6",
      lightest: "#EBFAFF",
      bright: "#2CA7E4",
      dark: "#29324F",
      darker: "#20273F",
      darkest: "#1B2036",
    },
  },
};
