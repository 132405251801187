import React, { useContext } from "react";
import { EventContext, ChatMessage, firebase } from "Components";
import { GridColumn } from "notes";
import { useCollectionData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
export const Live = () => {
  const { event } = useContext(EventContext);
  const ref = firebase.firestore().collection(`artist_views/${event.id}/cards`);
  const [cards] = useCollectionData(
    ref.where("status", "==", "live").orderBy("queueOrder")
  );

  return <Container stretch>{cards?.map(ChatMessage)}</Container>;
};

const Container = styled(GridColumn)`
  padding: 8px 24px 0 24px;
`;
