import React, { createContext } from "react";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firebase } from "Components";
import { useParams } from "react-router-dom";

export const EventContext = createContext();
export const EventConsumer = EventContext.Consumer;
export const EventProvider = ({ children }) => {
  const { eventId } = useParams();
  const [event, loading, error] = useDocumentData(
    firebase.firestore().doc(`events/${eventId}`)
  );

  const eventSettingsRef = firebase.firestore().doc(`artist_views/${eventId}`);

  const [eventSettings] = useDocumentData(eventSettingsRef);

  const setContentSize = (contentSize) => {
    eventSettingsRef.update({ contentSize });
  };

  const contentSize = eventSettings?.contentSize;

  if (loading) {
    return null; //TODO: loading strategy
  }

  const fullEvent = {
    id: eventId,
    ...event,
    start: DateTime.fromJSDate(event.startDate.toDate()),
    end: DateTime.fromJSDate(event.endDate.toDate()),
    encoreEnd: DateTime.fromJSDate(event.encoreEnd.toDate()),
  };
  return (
    <EventContext.Provider
      value={{ event: fullEvent, loading, error, contentSize, setContentSize }}
    >
      {children}
    </EventContext.Provider>
  );
};
