// Import FirebaseAuth and firebase.
import React, { useContext } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebase, UserContext } from "Components";
import { H1, Link } from "notes";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  ],
};

export const Login = () => {
  const { user } = useContext(UserContext);
  return (
    <div>
      <H1>Set.Live Manager App</H1>
      <H1>
        {user
          ? `Signed in as ${user.email} ${user.displayName}`
          : "Not Signed In"}
      </H1>
      {user && (
        <Link
          onClick={() => {
            firebase.auth().signOut();
            window.location.reload();
          }}
        >
          Sign Out
        </Link>
      )}
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
};
