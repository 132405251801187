import { ModalProvider, EventProvider } from "Components";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Root } from "./Root";
import { Manager } from "./Manager";
import { Login } from "./Login";

export const Routes = () => {
  return (
    <Router>
      <ModalProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/:eventId" component={EventRoutes} />
          <Redirect to="/login" />
        </Switch>
      </ModalProvider>
    </Router>
  );
};

const EventRoutes = () => {
  return (
    <Switch>
      <EventProvider>
        <Route exact path="/:eventId" component={Root} />
        <Route exact path="/:eventId/manager" component={Manager} />
      </EventProvider>
    </Switch>
  );
};
