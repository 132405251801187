import React from "react";
import styled from "styled-components";
import { GridColumn } from "notes";
import { ChatMessage, firebase } from "Components";
import { useDocumentData } from "react-firebase-hooks/firestore";

const messages = [
  { type: "note", content: "Quae fuerit causa, mox videro" },
  { type: "encore", content: ["#1 Song Name", "#2 Song Name", "#3 Song Name"] },
  {
    type: "supporters",
    content: [
      { displayName: "DisplayName1" },
      { displayName: "DisplayName1" },
      { displayName: "DisplayName1" },
      { displayName: "DisplayName1" },
      { displayName: "DisplayName1" },
      { displayName: "DisplayName1", amount: 500 },
      { displayName: "DisplayName1", amount: 500 },
    ],
  },
  {
    type: "leaderboard",
    content: ["#1 DisplayName", "#2 DisplayName", "#3 DisplayName"],
  },
  {
    type: "question",
    content: {
      question: "Question 1 fuerit causa, mox videro intellegebat contineri?",
      username: "miktimrob",
    },
  },
  {
    type: "question",
    content: {
      question: "Question 2 fuerit causa, mox videro intellegebat contineri?",
      username: "miktimrob",
    },
  },
  {
    type: "question",
    content: {
      question: "Question 3 fuerit causa, mox videro intellegebat contineri?",
      username: "miktimrob",
    },
  },
  {
    type: "question",
    content: {
      question: "Question 4 fuerit causa, mox videro intellegebat contineri?",
      username: "miktimrob",
    },
  },
  {
    type: "question",
    content: {
      question: "Question 5 fuerit causa, mox videro intellegebat contineri?",
      username: "miktimrob",
    },
  },
  {
    type: "question",
    content: {
      question: "Question 6 fuerit causa, mox videro intellegebat contineri?",
      username: "miktimrob",
    },
  },
];

export const Preview = ({ ...props }) => {
  const [view, load, err] = useDocumentData(
    firebase.firestore().doc(`artist_views/sqKamvp183d6u2b5WW6L`)
  );
  const cards = view?.cards || {};
  return (
    <Container stretch>
      {Object.values(messages).map((message) => (
        <ChatMessage {...message} />
      ))}
    </Container>
  );
};

const Container = styled(GridColumn)`
  padding: 8px 24px 0 24px;
`;
