import React from "react";
import styled from "styled-components";
import { ScaleElements } from "Components";
import { GridColumn, Grid, Icon, H2, H3 } from "notes";
import { RenderMessage } from "./RenderMessage";

export const getCardIcon = (type) => {
  switch (type) {
    case "question":
      return <Icon form name="QuestionHighlight" />;
    case "encore":
      return <Icon form name="SetList" />;
    case "supporters":
      return <Icon form name="Audience" />;
    case "leaderboard":
      return <Icon form name="Trophy" />;
    case "note":
      return <Icon form name="Pencil" />;
    default:
      return "";
  }
};

export const ChatMessage = ({ type = "note", content }) => {
  console.log('ChatMessage:', content);

  return (
    <Container row stretch>
      <LeftContainer noShrink type={type} xCenter yStart>
        {getCardIcon(type)}
      </LeftContainer>
      <RightContainer type={type} stretch>
        <Title type={type}>
          {type !== "question" ? type : content.username}
        </Title>
        <RenderMessage type={type} content={content} />
      </RightContainer>
    </Container>
  );
};

const Container = styled(ScaleElements)`
  margin-bottom: 8px;
`;

const LeftContainer = styled(GridColumn)`
  background-color: ${(props) =>
    props.type === "note"
      ? props.theme.palette.blue.primary
      : props.type === "supporters"
      ? props.theme.palette.green.primary
      : props.type === "encore"
      ? props.theme.palette.purple.primary
      : props.type === "question"
      ? props.theme.palette.teal.primary
      : props.theme.palette.yellow.primary};
  border-radius: 8px 0 0 8px;
  min-height: 100px;
  padding: 0 18px;

  svg {
    margin-top: 25px;

    path {
      fill: #fff;
    }
  }
`;

const Title = styled(H2)`
  ${(props) => props.theme.fonts.default};
  margin-right: 24px;
  text-transform: ${(props) =>
    props.type !== "question" ? "capitalize" : "none"};
  color: ${(props) =>
    props.type === "note"
      ? props.theme.palette.blue.primary
      : props.type === "supporters"
      ? props.theme.palette.green.primary
      : props.type === "encore"
      ? props.theme.palette.purple.primary
      : props.type === "question"
      ? props.theme.palette.teal.primary
      : props.theme.palette.yellow.primary};
`;

const RightContainer = styled(Grid)`
  background-color: ${(props) => props.theme.palette.blue.dark};
  padding: 24px;

  ${H2} {
    margin-bottom: 4px;
  }
`;
