import React from "react";
import styled from "styled-components";
import { GridColumn, P } from "notes";

export const ProgressBar = ({ title, percent }) => {
  return (
    <Container yCenter>
      <Label>{title}</Label>
      <Progress percent={percent} />
    </Container>
  );
};

const Container = styled(GridColumn)`
  padding: 12px 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.primary};
`;

const Label = styled(P)`
  color: #ffffff;
`;

const Progress = styled.div`
  height: 8px;
  margin: 5px 0;
  background: linear-gradient(270deg, #b4ec51 0%, #429321 100%);
  border-radius: 4px;
  width: ${(props) => props.percent}%;
`;
