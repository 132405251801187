import React from "react";
import styled from "styled-components";
import {
  Modal,
  H3,
  P,
  Label,
  Select,
  Input,
  ButtonPrimary,
  ButtonSecondary,
  GridRow,
} from "notes";

export const DelayModal = ({ onClose, ...props }) => {
  let options = [];
  let length = 13;
  for (let index = 1; index < length; index++) {
    options.push({ id: index, text: `${index * 5} Minutes` });
  }

  const handleClose = () => {
    onClose(false);
  };

  return (
    <ModalStyle setOpen={handleClose} size="medium" {...props}>
      <H3>Delay Show Start</H3>
      <P>Fans will receive a notification of the delayed start time.</P>
      <LabelStyle light>Scheduled start</LabelStyle>
      <P style={{ fontWeight: "600" }}>7:00 PM PST</P>

      <LabelStyle>Delay amount</LabelStyle>
      <Select placeholder="Select an option..." options={options} />

      <LabelStyle>Include a message to the fans (optional)</LabelStyle>
      <Input placeholder="Anything you want them to know?" />

      <Actions xEnd yEnd>
        <ButtonSecondary onClick={handleClose}>Cancel</ButtonSecondary>
        <Primary>Confirm</Primary>
      </Actions>
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
`;

const LabelStyle = styled(Label).attrs((props) => ({
  light: props.light || false,
}))`
  color: ${(props) =>
    props.light ? props.theme.palette.gray.lighter : props.theme.palette.black};
`;

const Actions = styled(GridRow)`
  width: 100%;
  margin-top: 40px;
  flex-grow: 1;
`;

const Primary = styled(ButtonPrimary)`
  margin-left: 8px;
`;
