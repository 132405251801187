import React, { useContext, useState } from "react";
import { Chat as MAXChat } from "max-chat";
import { EventContext, firebase } from "Components";
import { Icon, withDefaultMedia } from "notes";
import { useCollection } from "react-firebase-hooks/firestore";
import "./chat.css";

const formatCurrency = (amount) => {
  return `$${(amount / 100).toFixed(2)}`;
};

const pinnedChatHelperText =
  "Pin your message to help support the artist, increase your support tier, and have your message standout from others!";

export const Chat = withDefaultMedia(({ matches, ...props }) => {
  const { event } = useContext(EventContext);
  const { id } = event;
  const [superchats, loading, error] = useCollection(
    firebase
      .firestore()
      .collection("payment_items")
      .where("eventId", "==", id)
      .where("type", "==", "superchat")
      .where("status", "==", "public")
      .orderBy("amount")
  );

  const pinnedOptions = superchats?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    title: doc.data().label,
    content: formatCurrency(doc.data().amount),
    icon: <Icon form name="Clock" />,
  }));

  let levels = {};
  Object.keys(event.supportLevels).map((key) => {
    let level = event.supportLevels[key];
    levels[key] = {
      ...level,
      icon: level.selectedIcon
        ? `${process.env.REACT_APP_ASSETS_PATH}/${level.selectedIcon}`
        : null,
    };
  });

  return (
    <MAXChat
      db={firebase.database}
      auth={firebase.auth}
      firestore={firebase.firestore}
      path={id}
      pinnedOptions={pinnedOptions}
      pinnedChatHelperText={pinnedChatHelperText}
      supportLevels={levels}
      emoji={!matches.small}
    />
  );
});
