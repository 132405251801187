import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, H2, H3 } from "notes";
import { useListVals, useObject } from "react-firebase-hooks/database";
import { firebase, TimeContext, EventContext } from "Components";
import { Duration } from "luxon";

export const formatCurrency = (amount) => {
  return `$${(amount / 100).toFixed(2)}`;
};

export const RenderMessage = ({ type, content, managed, ...props }) => {
  if (type === "note") {
    return <WhiteText>{content}</WhiteText>;
  }

  if (type === "encore") {
    return <Encore />;
  }

  if (type === "supporters") {
    const displayed = content.sort((a, b) => b.amount - a.amount).slice(0, 8);
    return (
      <GridRow style={{ flexWrap: "wrap" }}>
        {displayed?.map((name, index) => (
          <>
            {index !== 0 && (
              <Divider managed={managed} yCenter>
                •
              </Divider>
            )}
            {!!name.amount && name.amount > 5000 && (
              <Amount>{formatCurrency(name.amount)}</Amount>
            )}
            <WhiteText>{name.displayName}</WhiteText>
          </>
        ))}
        {displayed.length < content.length && (
          <>
            <Divider managed={managed} yCenter>
              •
            </Divider>
            <WhiteText>And {content.length - displayed.length} more!</WhiteText>
          </>
        )}
      </GridRow>
    );
  }

  if (type === "leaderboard") {
    return <Leaderboard />;
  }

  if (type === "question") {
    return (
      <GridRow style={{ flexWrap: "wrap" }}>
        <WhiteText>
          {!!content.amount && (
            <Amount
              style={{
                display: "inline-block",
                lineHeight: "initial",
                backgroundColor: "#39A4A1",
              }}
            >
              {formatCurrency(content.amount)}
            </Amount>
          )}
          “{content.question}”
        </WhiteText>
      </GridRow>
    );
  }
};

const Leaderboard = () => {
  const { event } = useContext(EventContext);
  const [leaders] = useListVals(
    firebase.database().ref(`${event.id}/leaderboard/leaders`).limitToFirst(3)
  );
  return (
    <GridColumn>
      {leaders?.map(({ rank, user }) => (
        <WhiteText>
          #{rank} {user}
        </WhiteText>
      ))}
    </GridColumn>
  );
};

const Encore = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const timeToEnd = Duration.fromMillis(event.encoreEnd - time).shiftTo(
    "minutes",
    "seconds",
    "milliseconds"
  );
  const [apiVotes, load] = useObject(
    firebase.database().ref(`${event.id}/songs/ranks`)
  );
  if (load) {
    return null;
  }
  const voteData = apiVotes?.val() || {};
  const songs = Object.keys(event.encoreSongs)
    .map((songId) => ({
      id: songId,
      title: event.encoreSongs[songId].title,
      percent: voteData[songId] * 100 || 0,
    }))
    .sort((a, b) => b.percent - a.percent);
  return (
    <GridColumn>
      {songs.slice(0, 3).map(({ title }, idx) => (
        <WhiteText>
          #{idx + 1} {title}
        </WhiteText>
      ))}
      <TimeRemaing minutes={timeToEnd.minutes} seconds={timeToEnd.seconds} />
    </GridColumn>
  );
};

const TimeRemaing = ({ minutes, seconds }) => {
  console.log('minutes, seconds', minutes, seconds);
  return (
    <RemainingContainer>
      {seconds < 0 || minutes < 0 ? (
        <H2 style={{ color: "#FFF" }}>FINAL</H2>
      ) : (
        <>
          <H2 style={{ color: "#FFF" }}>
            {minutes < 1 ? "< 1" :  minutes } MIN
          </H2>
          <H3 style={{ color: "#FFF" }}>TO VOTE</H3>
        </>
      )}
    </RemainingContainer>
  );
};

const RemainingContainer = styled.div`
  background: #1a1f35;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  top: 12px;
  bottom: 12px;
  padding: 0 20px;
`;

const Amount = styled(H2)`
  background-color: ${(props) => props.theme.palette.green.primary};
  border-radius: 100px;
  color: #fff;
  ${(props) => props.theme.fonts.default};
  font-weight: 600;
  margin-right: 9px;
  padding: 0 8px;
`;

const Divider = styled(H2)`
  color: #fff;
  margin: ${(props) => (props.managed ? "0 4px" : "0 9px")};
`;

const WhiteText = styled(H2)`
  ${(props) => props.theme.fonts.default};
  font-weight: 600;
  color: #ffffff;
  text-transform: none;
`;
