import { firebase } from "Components";

const SCRIPT = [
  { action: 'message', message: { type: 'superchat', user: 'Bob', uid: '123', content: 'This is a question', amount: 100 }, delay: 0 },
  { action: 'message', message: { type: 'donate', user: 'Bob', uid: '123', content: 'Bob donated $10', amount: 1000 }, delay: 10000 },
  { action: 'message', message: { type: 'song', user: 'Bob', uid: '123', content: 'Bob voted for Song A', songs: { '5a8fd254-75dc-4f8b': 2 }, amount: 200 }, delay: 5000 },
  { action: 'message', message: { type: 'superchat', user: 'Bob', uid: '123', content: 'This is a question from Bob', amount: 100 }, delay: 3000 },
  { action: 'message', message: { type: 'superchat', user: 'Joe', uid: '124', content: 'This is a question from Joe', amount: 500 }, delay: 5000 },
  { action: 'message', message: { type: 'donate', user: 'Kat', uid: '126', content: 'Kat donated $100', amount: 10000 }, delay: 2500 },
  { action: 'message', message: { type: 'superchat', user: 'Jim', uid: '125', content: 'This is a question from Jim', amount: 800 }, delay: 5000 },
  { action: 'message', message: { type: 'donate', user: 'Joe', uid: '124', content: 'Joe donated $35', amount: 3500 }, delay: 2500 },
  { action: 'message', message: { type: 'superchat', user: 'Kat', uid: '126', content: 'This is a question from Kat', amount: 1000 }, delay: 5000 },
]

const handler = firebase.functions().httpsCallable('manager_view-demo');

const sendMessage = (eventId, index) => {
  const message = SCRIPT[index];
  if (message) {
    const delay = message.delay || 0;
    
    message.eventId = eventId;

    console.log(`sendMessage #${index}, delay ${delay}`);

    setTimeout(() => {
      console.log(`Sending ${index}`);

      handler(message);
      
      sendMessage(eventId, index+1);
    }, delay);
  }
}

export const runDemo = async (eventId) => {
  console.log('runDemo!');

  await handler({
    action: 'init',
    eventId: eventId
  });

  setTimeout(() => {
    sendMessage(eventId, 0);
  }, 5000);
}
