import React, { useContext, useEffect, useRef } from "react";
import { EventContext, SortableCards, firebase, Dropdown } from "Components";
import styled from "styled-components";
import { P } from "notes";
import { PinSeparator } from "./Components";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { fontSizeOptions } from "Data";
export const LiveCards = () => {
  const { event, contentSize, setContentSize } = useContext(EventContext);
  const artistViewRef = firebase.firestore().doc(`artist_views/${event.id}`);
  const ref = firebase.firestore().collection(`artist_views/${event.id}/cards`);
  const [eventSettings] = useDocumentData(
    firebase.firestore().doc(`artist_views/${event.id}`)
  );
  const [liveCards, liveLoad, err] = useCollectionData(
    ref.where("status", "==", "live")
  );

  return (
    <Container>
      <div style={{ display: "flex" }}>
        <Header>Artist</Header>
        <Dropdown
          active={contentSize}
          items={fontSizeOptions}
          textLink
          onClick={(size) => setContentSize(size)}
        />
      </div>
      <SortableCards
        query={ref.where("status", "==", "live").orderBy("queueOrder")}
        index={"queueOrder"}
      />
      <Header>Queue</Header>
      <SortableCards
        query={ref.where("status", "==", "pinned").orderBy("queueOrder")}
        index={"queueOrder"}
      />
      <PinSeparator />
      <SortableCards
        query={ref.where("status", "==", "queue").orderBy("queueOrder")}
        index={"queueOrder"}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 24px;
`;

const Header = styled(P)`
  color: #fff;
`;
