import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, Radio, Label, GridRow } from "notes";
import { EventContext } from "Components";
import { fontSizeOptions } from "Data";

export const SizeCard = ({ children, ...props }) => {
  const { setContentSize, contentSize } = useContext(EventContext);

  return (
    <Card>
      <Label>Display font size</Label>
      {fontSizeOptions.map(({ title, subtitle, size }) => (
        <RadioField
          onChange={() => setContentSize(size)}
          checked={contentSize === size}
          title={title}
        >
          {subtitle}
        </RadioField>
      ))}

      <ShadowBox xStart yCenter>
        {children}
      </ShadowBox>
    </Card>
  );
};

const ShadowBox = styled(GridRow)`
  background-image: radial-gradient(
    circle at top,
    rgba(196, 210, 231, 0.32) 0.07%,
    rgba(255, 255, 255, 0) 100%
  );
  border-top: 1px solid #c4d2e7;
  margin-top: 24px;
  height: 64px;
  width: 100%;
`;

const RadioField = styled(Radio)`
  margin: 9px 0;
`;

const Card = styled(GridColumn)`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.48);
  padding: 24px 40px 0 40px;
  width: 100%;
  max-width: 392px;

  ${Label} {
    margin-bottom: 16px;
  }
`;
