import React, { useState, useContext } from "react";
import styled from "styled-components";
import { theme } from "theme";
import {
  GridColumn,
  GridRow,
  H1,
  H4,
  H3,
  Icon,
  ButtonSecondary,
  ButtonPrimary,
} from "notes";
import {
  Header,
  Tabs,
  Video,
  ModalContext,
  CreateNote,
  EventContext,
  TimeContext,
} from "Components";
import {
  Moderation,
  Encore,
  Leaderboard,
  LiveCards,
  ArchiveCards,
  Chat,
} from "./Components";
import { Duration } from "luxon";

const sideTabStyle = {
  backgroundColor: theme.palette.blue.darker,
  borderBottom: `1px solid ${theme.palette.gray.primary}`,
  height: "44px",
  overflow: "hidden",
};

export const Manager = ({ ...props }) => {
  const { displayModal } = useContext(ModalContext);
  const { event } = useContext(EventContext);

  return (
    <Container>
      <Header />
      <Content stretch>
        <LeftSide stretch>
          <EventInfo yCenter>
            <Avatar />
            <H4>{event.title}</H4>
          </EventInfo>
          <CountdownClock />
          <ChatContainer>
            <Tabs tabStyle={sideTabStyle}>
              <div
                label="Chat"
                style={{
                  background: "white",
                  height: "500px",
                  position: "relative",
                }}
              >
                <Chat />
              </div>

              <Encore label="Encore" />
              <Leaderboard label="Leaderboard" />
            </Tabs>
          </ChatContainer>
        </LeftSide>
        <RightSide>
          <Tabs tabStyle={{ backgroundColor: theme.palette.blue.dark }}>
            <div
              label={
                <>
                  <Icon tertiary name="Dashboard" /> Feed
                </>
              }
            >
              <LiveCards />
              <CreateNote />
            </div>
            <div
              label={
                <>
                  <Icon form name="Download" /> Archive
                </>
              }
            >
              <ArchiveCards />
            </div>
            <Moderation
              label={
                <>
                  <Icon form name="Audience" /> Moderation &amp; Messaging
                </>
              }
            />
          </Tabs>
          {/* <ButtonContainer>
            {live ? (
              <EndShowButton onClick={() => setLive(false)}>
                End Show
              </EndShowButton>
            ) : (
              <>
                <DelayButton onClick={() => displayModal("delay")}>
                  Delay Show Start
                </DelayButton>
                <Primary onClick={() => setLive(true)}>Start Show</Primary>
              </>
            )}
          </ButtonContainer> */}
        </RightSide>
      </Content>
    </Container>
  );
};

const CountdownClock = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const timeToStart = Duration.fromMillis(event.start - time).shiftTo(
    "hours",
    "minutes",
    "seconds",
    "milliseconds"
  );

  const timeToEnd = Duration.fromMillis(time - event.end).shiftTo(
    "hours",
    "minutes",
    "seconds",
    "milliseconds"
  );
  const countdown = time > event.end ? timeToEnd : timeToStart;
  const live = time > event.start && time < event.end;

  return live ? (
    <LiveFeed>
      <Feed>
        <Video id={event.videoId} />
      </Feed>
      <GridColumn style={{ marginLeft: "24px", paddingTop: "8px" }}>
        <Badge xCenter yCenter active>
          <H3>LIVE</H3>
        </Badge>
      </GridColumn>
    </LiveFeed>
  ) : (
    <Countdown>
      <H1>{`${formatTime(countdown.hours)}:${formatTime(
        countdown.minutes
      )}:${formatTime(countdown.seconds)}`}</H1>
      <H3>
        {time > event.start ? "Time Since Show End" : "Time to Show Start"}
      </H3>
    </Countdown>
  );
};

const formatTime = (n) => {
  return n < 10 ? "0" + n : n;
};

const Container = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.blue.darkest};
  width: 100%;
  min-height: 100vh;
`;

const Content = styled(GridRow)`
  width: 100%;
`;

const LeftSide = styled(GridColumn)`
  width: 100%;
  max-width: 352px;
`;

const EventInfo = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.blue.dark};
  height: 64px;
  padding: 0 24px;

  ${H4} {
    color: #fff;
    margin-left: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Avatar = styled(GridColumn)`
  background-image: url(${(props) => props.background});
  background-position: center center;
  background-size: cover;
  border: 1px solid #fff;
  border-radius: 12px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
`;

const LiveFeed = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.blue.darker};
  padding: 16px 24px;
  width: 100%;
  height: 136px;
`;

const Countdown = styled(LiveFeed)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${H1}, ${H3} {
    color: #fff;
    text-align: center;
  }
`;

const Feed = styled(GridColumn)`
  width: 184px;
`;

const ChatContainer = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.blue.darker};
  width: 100%;
  flex-grow: 1;
`;

const RightSide = styled(GridColumn)`
  width: 100%;
  position: relative;
`;

const Badge = styled(GridRow)`
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.red.primary
      : props.theme.palette.gray.lightest};
  ${(props) =>
    props.active &&
    `box-shadow: 0 0 16px 0 ${props.theme.palette.red.primary};`}
  border-radius: 2px;
  cursor: default;
  width: 40px;
  height: 24px;
  ${H3} {
    color: ${(props) =>
      props.active ? "#ffffff" : props.theme.palette.gray.lighter};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }
`;

const EndShowButton = styled(ButtonSecondary)`
  border-color: ${(props) => props.theme.palette.red.primary};
  color: ${(props) => props.theme.palette.red.primary};
  width: 184px;

  &:hover {
    background-color: ${(props) => props.theme.palette.red.primary};
    border-color: ${(props) => props.theme.palette.red.primary};
    color: #fff;
  }
`;

const ButtonContainer = styled(GridRow)`
  position: absolute;
  right: 200px;
  top: -48px;

  @media only screen and (min-width: 1300px) {
    right: 24px;
    top: 12px;
  }
`;

const Primary = styled(ButtonPrimary)`
  margin-left: 24px;
`;

const DelayButton = styled(ButtonSecondary)`
  border-color: #fff;
  color: #fff;
`;
