import React, { useState } from "react";
import styled from "styled-components";
import { Toggle, Icon } from "notes";
import {
  Container,
  HeaderRow,
  HeaderColumn,
  Row,
  Column,
  StaticWidth,
  ColumnPadding,
} from "./AttendeeTable";

export const ChatTable = React.memo(({ items, muteChat, ...props }) => {
  const [sort, setSort] = useState({ key: "flaggedCount", ascending: true });
  const handleSort = (key) => {
    if (sort.key === key) {
      setSort({ ...sort, ascending: !sort.ascending });
    } else {
      setSort({ key, ascending: true });
    }
  };
  let sortedItems = [];
  if (items.length) {
    switch (typeof items[0][sort.key]) {
      case "number":
        sortedItems = items.sort((a, b) => a[sort.key] - b[sort.key]);
        break;
      case "string":
        sortedItems = items.sort((a, b) =>
          a[sort.key]?.localeCompare(b[sort.key])
        );
        break;
      default:
        sortedItems = items.sort((a, b) => a[sort.key] - b[sort.key]);
        break;
    }
    if (sort.ascending) {
      sortedItems = sortedItems.reverse();
    }
  }

  return (
    <Container stretch>
      <HeaderRow>
        <HeaderColumn onClick={() => handleSort("user")}>
          Display Name
        </HeaderColumn>
        <HeaderColumn stretch onClick={() => handleSort("content")}>
          Chat Message
        </HeaderColumn>
        <HeaderColumn onClick={() => handleSort("flaggedCount")}>
          Flagged
        </HeaderColumn>
        <HeaderColumn
          style={{ width: "152px" }}
          onClick={() => handleSort("muted")}
        >
          Chat
        </HeaderColumn>
      </HeaderRow>
      {!!items &&
        items.map(
          ({ user, uid, content, timestamp, flaggedCount, muted, id }) => (
            <Row key={`${uid}${timestamp}`}>
              <ColumnPadding>{user}</ColumnPadding>
              <ChatColumn stretch>{content}</ChatColumn>
              <Column>{flaggedCount}</Column>
              <StaticWidth style={{ width: "152px" }}>
                <Toggle checked={!muted} onChange={() => muteChat(id, muted)} />
                {/* <Icon tertiary name="Email" />
              <Icon tertiary name="Delete" /> */}
              </StaticWidth>
            </Row>
          )
        )}
    </Container>
  );
});

const ChatColumn = styled(ColumnPadding)`
  white-space: normal;
  overflow: visible;
  padding-right: 24px;
`;
