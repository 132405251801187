import React, { createContext, useState, useEffect } from "react";
import { firebase } from "Components";

export const UserContext = createContext();
export const UserConsumer = UserContext.Consumer;
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async(user) => {

      console.log('onAuthStateChanged', user);

      // Let's try logging in with the session cookie
      const options = {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'include'
      };

      let data = null;

      try {
        //
        // TODO: Use config
        //
        const path = '/auth/checkSession';

        data = await fetch(path, options).then(async (res) => {
          try {
            return res.json();
          } catch (error) {
            console.log("Error parsing JSON");
          }

          return {status: "failure", message: "Unknown Error"};
        });
      } catch (error) {
        console.log("Error logging in via session token", error);
      }

      if (data && data.status == 'success') {
        try {
          const res = await firebase.auth().signInWithCustomToken(data.token);

          console.log('signInWithCustomToken succeeded');

          if (res.user && !user) {
            // The signin was successful, but we return early because this function
            // will get called again automatically because we've triggered
            // onAuthStateChanged.
            return;
          }
        } catch (error) {
          console.log("Error logging in with token", error);
        }
      } else if (data && data.status == 'logout') {
        if (user) {
          console.log('Session has ended...logging out');

          await firebase.auth().signOut();
          
          return;
        }
      }

      if (user) {
        console.log("Got user", user);
        return setUser(user);
      }

      // If we get here we were unable to authenticate the user, so we need to
      // direct them to the authentication portal
      console.log("Authentication failed...redirecting to auth portal");

      //window.location = `https://stage.set.live/login?r=${window.location.href}`;
    });
  }, []);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};
