import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, Icon, Input } from "notes";
import { EventContext } from "Components/EventProvider";
import { firebase } from "Components";

export const CreateNote = ({ onSubmit, ...props }) => {
  const { event } = useContext(EventContext);
  const [active, setActive] = useState(false);
  const [note, setNote] = useState("");
  const [fieldRef, setFieldRef] = useState(false);

  const handleClickOutside = (evt) => {
    if (!fieldRef.contains(evt.target)) {
      setActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const handleSubmit = async () => {
    const res = await firebase
      .firestore()
      .collection(`artist_views/${event.id}/cards`)
      .add({
        type: "note",
        status: "pinned",
        queueOrder: 0,
        content: note,
      });
    !!onSubmit && onSubmit(note);
    setActive(false);
    setNote("");
  };

  return (
    <Container>
      <RoundedContainer
        ref={(component) => setFieldRef(component)}
        active={active}
        yCenter
      >
        {active ? (
          <FieldContainer hasText={!!note}>
            <StyledInput
              value={note}
              onChange={setNote}
              style={{ whiteSpace: "nowrap", backgroundColor: "transparent" }}
              placeholder="Send a message to artist (will go to top of queue)..."
            />
            <Icon onClick={handleSubmit} selector name="Send" />
          </FieldContainer>
        ) : (
          <IconContainer xCenter yCenter onClick={() => setActive(true)}>
            <Icon tertiary name="Pencil" />
          </IconContainer>
        )}
      </RoundedContainer>
    </Container>
  );
};

const FieldContainer = styled(GridRow)`
  svg {
    cursor: ${(props) => (props.hasText ? "pointer" : "default")};
    margin-left: 21px;
    width: 40px;
    height: 40px;
    path {
      fill: ${(props) =>
        props.hasText
          ? props.theme.palette.black
          : props.theme.palette.gray.primary};
    }
  }
`;

const IconContainer = styled(GridColumn)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: #ffffff;
    }
  }
`;

const Container = styled(GridRow)`
  position: fixed;
  bottom: 40px;
  right: 40px;
  left: 392px;
  justify-content: flex-end;
`;

const RoundedContainer = styled(GridColumn).attrs({ as: "div" })`
  display: flex;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.active ? "#ffffff" : props.theme.palette.blue.primary};
  border-radius: 28px;
  width: ${(props) => (props.active ? "100%" : "56px")};
  height: 56px;
  transition: width ease 0.5s, background-color ease 0.5s;
  overflow: hidden;

  ${(props) =>
    props.active &&
    `
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: 0 12px 32px 0 #000000;
  `};

  &:hover {
    background-color: ${(props) =>
      !props.active && props.theme.colors.noteButtonHover};
    box-shadow: 0 12px 32px 0 #000000;
  }
`;

const StyledInput = styled(Input)`
  border: none;
  &:focus {
    border: none;
    box-shadow: none;
  }
`;
