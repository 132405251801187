import React from "react";
import { ThemeProvider } from "styled-components";
import { EventProvider, TimeProvider, UserProvider } from "Components";
import { Routes } from "./Routes";
import { theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <TimeProvider>
        <UserProvider>
          <Routes />
        </UserProvider>
      </TimeProvider>
    </ThemeProvider>
  );
}

export default App;
